import { array, shape, number, string, bool, any, arrayOf } from 'prop-types';

/* commonly used data objects, listed alphabetically */

const ImpactiveTypes = {
  campaign: shape({
    active: bool,
    approved: bool,
    custom_fields: array,
    has_active_subscription: bool,
    id: number.isRequired,
    slug: string,
    settings: shape({
      is_biden_national: bool,
    }),
    tags: array,
  }),

  // TODO: add all needed types
  activity: shape({
    id: number.isRequired,
    type: string,
  }),

  messageThread: shape({
    id: number,
    body: string,
    kind: string,
    created_at: string,
    from_me: bool,
    receiver: shape({}),
    receiver_raw: any,
    receiver_type: string,
    sender: shape({}),
    sender_raw: any,
    sender_type: string,
    type: string,
    unread: bool,
    user_activity: shape({}),
    latest_message_body: string,
    latest_message_at: string,
    users: arrayOf(shape({})),
    contact: any,
  }),
};

export default ImpactiveTypes;
