import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import translationEN from '../../../assets/locales/en/translation.json';
import translationES from '../../../assets/locales/es/translation.json';
import translationKO from '../../../assets/locales/ko/translation.json';
import { getSessionLocale, setSessionLocale } from '../services/session';

// TODO: load resources via XHR to save on bundle size (see: i18next-xhr-backend)
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  ko: {
    translation: translationKO,
  },
};

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  lng: getSessionLocale() || 'en',
  react: {
    useSuspense: false, // we might need it when we load translation files via XHR
  },
  resources,
});

// Also recall moment locale if any was stored
moment.locale(getSessionLocale() || 'en');

// Function sets application-wide language and persists in in session
export const setAppLanguage = (locale, temporary = false) => {
  i18n.changeLanguage(locale); // Make current instance of i18n use selected locale
  if (!temporary) {
    setSessionLocale(locale); // Save selected locale in session storage for next sessions
    moment.locale(locale); // Make moment use selected locale
  }
};

export default i18n;
